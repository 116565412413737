import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import About from "./About";
import Contact from "./Contact";
import Gallery from "./Gallery";
import { ThemeProvider } from "./ThemeContext";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <div className="home">
        <About />
        <Gallery />
        <Contact />
      </div>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
