import React from "react";
import "./Gallery.css";

function importAll(r) {
  return r.keys().map(r);
}

function Gallery() {
  const images = importAll(
    require.context("/public/images", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <div id="gallery">
      <div class="container">
        <div class="content">
          <div className="gallery">
            {[...images].reverse().map((image, index) => (
              <img
                className="gallery-image"
                key={index}
                src={image}
                alt="painting"
                loading="lazy"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
